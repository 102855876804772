<template>
  <div class="schedule-content-days-day">
    <div class="schedule-content-days-day-time">
      {{ item.start }}
    </div>

    <Speakers :speakers="item.speakers" />

    <div class="schedule-content-days-day-text">
      <div class="schedule-content-days-day-text-title">
        {{ item.title }}
      </div>

      <div v-if="showDescription" class="schedule-content-days-day-text-desc">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>

<script>
import Speakers from './Speakers'

export default {
  components: {
    Speakers
  },
  props: {
    item: {
      type: Object,
      required: true
    },

    showDescription: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">

.schedule-content-days-day {
  height: 190px;
  display: flex;
  max-width: 844px;
  border-bottom: solid 1px $color-grey;
  padding: 30px;
  position: relative;
  background-color: #F0F0F0;

  @media screen and (max-width: 1000px) {
    height: auto;
    flex-wrap: wrap;
    padding: 20px 21px 21px 19px;
  }
}

.schedule-content-days-day-text {
  width: 55%;

  @media screen and (max-width: 1000px) {
    margin-left: 30px;
    width: calc(85% - 30px);
  }

  @media screen and (max-width: 350px) {
    margin-left: 20px;
  }
}

.schedule-content-days-day-text-title {
  font-family: $font-r;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $color-text;
  max-height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.schedule-content-days-day-text-desc {
  margin-top: 15px;
  font-family: $font-r;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $color-text;
  opacity: 0.9;
  max-height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.schedule-content-days-day-time {
  width: 15%;
  font-family: $font-r;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $color-text;

  @media screen and (max-width: 1000px) {
    width: auto;
  }
}

.schedule-content-days-day-watch {
  position: absolute;
  right: -125px;
  top: 15px;
}

.schedule-content-days-day-live {
  position: absolute;
  display: flex;
  right: -40px;
}

.schedule-content-days-day-live-count {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 26px;
  max-width: 60px;
  border-radius: 5px;
  background-color: #F0F0F0;
}

.schedule-content-days-day-live-count-number {
  font-family: $font-r;
  color: $color-text;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
}

.day_now {
  background: var(--schedule-day-now) no-repeat;
  background-size: cover;
  border-bottom: 0;
  border-radius: 0 0 20px 20px;

  .schedule-content-days-day-time {
    color: #fff;
  }

  .schedule-content-days-day-watchers-text-name {
    color: #fff;
  }

  .schedule-content-days-day-watchers-position {
    color: #fff;
    opacity: 0.7;
  }

  .schedule-content-days-day-text-title {
    color: #fff;
  }

  .schedule-content-days-day-text-desc {
    color: #fff;
  }
}
</style>
