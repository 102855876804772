var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-content-days-day" },
    [
      _c("div", { staticClass: "schedule-content-days-day-time" }, [
        _vm._v(" " + _vm._s(_vm.item.start) + " ")
      ]),
      _c("Speakers", { attrs: { speakers: _vm.item.speakers } }),
      _c("div", { staticClass: "schedule-content-days-day-text" }, [
        _c("div", { staticClass: "schedule-content-days-day-text-title" }, [
          _vm._v(" " + _vm._s(_vm.item.title) + " ")
        ]),
        _vm.showDescription
          ? _c("div", { staticClass: "schedule-content-days-day-text-desc" }, [
              _vm._v(" " + _vm._s(_vm.item.description) + " ")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }