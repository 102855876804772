<template>
  <div class="schedule-content-days">
    <Item
      v-for="(item) in items"
      :key="item.id"
      class="schedule-content-days__item"
      :item="item"
      :show-description="showDescription"
    />
  </div>
</template>

<script>
import Item from './components/Item'

export default {
  components: {
    Item
  },

  props: {
    items: {
      type: Array,
      default: null
    },

    showDescription: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.schedule-content-days__item {
  &:first-child {
    border-radius: 20px 20px 0 0;
  }

  &:last-child {
    border-radius: 0 0 20px 20px;
    border-bottom: 0;
  }
}
</style>
