<template>
  <div
    class="schedule-content-days-day-watchers"
    :class="{'schedule-content-days-day-watchers_one_item': mobCanWatch }"
  >
    <div v-if="speakersLength === 1 && !mobCanWatch" class="schedule-content-days-day-watchers-conteiner-one">
      <div class="schedule-content-days-day-watchers-ava">
        <Avatar
          small
          :src="getLinkByFileName('speakers', 'main', speakers[0].photo_in_schedule)"
          :alt="speakers[0].name"
          size="44"
        />
      </div>

      <div class="schedule-content-days-day-watchers-text">
        <div class="schedule-content-days-day-watchers-text-name">
          {{ speakers[0].name }}
        </div>

        <div class="schedule-content-days-day-watchers-text-position">
          {{ speakers[0].position }}
        </div>
      </div>
    </div>

    <div v-if="speakersLength === 1 && mobCanWatch" class="schedule-content-days-day-watchers-conteiner-one">
      <v-tooltip
        content-class="page_schedule_tooltip bottom"
        bottom
      >
        <template v-slot:activator="{ on }">
          <div class="schedule-content-days-day-watchers-ava" v-on="on">
            <Avatar
              small
              :src="getLinkByFileName('speakers', 'main', speakers[0].photo_in_schedule)"
              :alt="speakers[0].name"
              size="44"
            />
          </div>
        </template>

        <div class="tooltip-arrow" />

        <div class="page-schedule-tooltip-content">
          <div class="schedule-tooltip-name">
            {{ speakers[0].name }}
          </div>

          <div class="schedule-tooltip-position">
            {{ speakers[0].position }}
          </div>
        </div>
      </v-tooltip>
    </div>

    <div v-if="speakersLength > 1" class="schedule-content-days-day-watchers-conteiner">
      <div class="schedule-content-days-day-watchers-conteiner-multi">
        <div
          v-for="(s, i) in speakers"
          :key="`${i}_${s.email}`"
        >
          <v-tooltip
            v-if="i < speakersShow"
            content-class="page_schedule_tooltip bottom"
            bottom
          >
            <template v-slot:activator="{ on }">
              <div
                class="schedule-content-days-day-watchers-ava multi"
                :class="{'multi-hover': hoverId !== null && hoverId !== i }"
                v-on="on"
                @mouseover="setHover(i)"
                @mouseout="setHover(null)"
              >
                <Avatar
                  small
                  :src="getLinkByFileName('speakers', 'main', s.photo_in_schedule)"
                  :alt="s.name"
                  size="44"
                />
              </div>
            </template>

            <div class="tooltip-arrow" />

            <div class="page-schedule-tooltip-content">
              <div class="schedule-tooltip-name">
                {{ s.name }}
              </div>

              <div class="schedule-tooltip-position">
                {{ s.position }}
              </div>
            </div>
          </v-tooltip>

          <v-tooltip
            v-if="i > speakersShow - 1 && i <= speakersShow"
            content-class="page_schedule_tooltip bottom"
            bottom
          >
            <template v-slot:activator="{ on }">
              <div
                class="schedule-content-days-day-watchers-ava multi plus"
                v-on="on"
              >
                +{{ speakersLength - speakersShow }}
              </div>
            </template>

            <div class="tooltip-arrow" />

            <div class="page-schedule-tooltip-content">
              <div
                v-for="(sp, j) in speakers"
                :key="`${sp.email}_${j}`"
                class="schedule-tooltip-speakers"
              >
                <div v-if="j >= speakersShow" class="schedule-tooltip-speaker">
                  <div class="schedule-tooltip-ava">
                    <Avatar
                      small
                      :src="getLinkByFileName('speakers', 'main', sp.photo_in_schedule)"
                      :alt="sp.name"
                      size="44"
                    />
                  </div>

                  <div>
                    <div class="schedule-tooltip-name">
                      {{ sp.name }}
                    </div>

                    <div class="schedule-tooltip-position">
                      {{ sp.position }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar'
import { getLinkByFileName } from '@/utils/mediaHelpers'

export default {
  components: {
    Avatar
  },

  props: {
    speakers: {
      type: Array,
      default: () => ([])
    },

    mobCanWatch: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      hoverId: null
    }
  },

  computed: {
    speakersLength () {
      return this.speakers?.length ?? 0
    },

    speakersShow () {
      return this.mobCanWatch ? 0 : (this.speakersLength > 5 ? 4 : 5)
    }
  },

  methods: {
    setHover (i) {
      this.hoverId = i
    },

    handleError (e) {
      e.target.src = require('@/assets/img/theme/person-plug-mini.png')
    },

    getLinkByFileName
  }
}
</script>

<style lang="scss">
.schedule-content-days-day-watchers {
  width: 270px;

  @media screen and (max-width: 1000px) {
    order: 2;
    width: 100%;
    margin-top: 15px;
  }
}

.schedule-content-days-day-watchers-conteiner {
  margin: 0 15px;

  &-one,
  &-multi {
    display: flex;

    > * {
      z-index: 1;

      /* stylelint-disable max-nesting-depth */
      &:hover {
        z-index: 1000000000090900090000;
      }
      /* stylelint-enable max-nesting-depth */
    }
  }

  @media screen and (max-width: 1000px) {
    margin: 15px 0 0;
  }
}

.schedule-content-days-day-watchers-ava {
  width: 48px;
  height: 48px;

  img {
    border-radius: 100px;
    border: 2px solid #fff;
  }
}

.schedule-content-days-day-watchers-ava.multi {
  margin-right: -10px;
  cursor: pointer;
  display: flex;

  img {
    z-index: 5;
  }

  &:hover {
    transform: scale(1.2);
  }
}

.schedule-content-days-day-watchers-ava.multi-hover {
  opacity: 0.2;

  img {
    z-index: 0;
  }
}

.schedule-content-days-day-watchers-ava.plus {
  border: 2px solid #fff;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: var(--main-secondary-background-color);
  justify-content: center;
  align-items: center;
  font-family: $font-r;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  position: relative;
  z-index: 6;

  &:hover {
    //opacity: 0.9;
  }
}

.schedule-content-days-day-watchers-text {
  padding-left: 15px;
}

.schedule-content-days-day-watchers-text-name {
  font-family: $font-r;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $color-text;
}

.schedule-content-days-day-watchers-text-position {
  font-family: $font-r;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: $color-grey;
  letter-spacing: -0.02em;
}
</style>
