var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "schedule-content-days-day-watchers",
      class: { "schedule-content-days-day-watchers_one_item": _vm.mobCanWatch }
    },
    [
      _vm.speakersLength === 1 && !_vm.mobCanWatch
        ? _c(
            "div",
            { staticClass: "schedule-content-days-day-watchers-conteiner-one" },
            [
              _c(
                "div",
                { staticClass: "schedule-content-days-day-watchers-ava" },
                [
                  _c("Avatar", {
                    attrs: {
                      small: "",
                      src: _vm.getLinkByFileName(
                        "speakers",
                        "main",
                        _vm.speakers[0].photo_in_schedule
                      ),
                      alt: _vm.speakers[0].name,
                      size: "44"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "schedule-content-days-day-watchers-text" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "schedule-content-days-day-watchers-text-name"
                    },
                    [_vm._v(" " + _vm._s(_vm.speakers[0].name) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "schedule-content-days-day-watchers-text-position"
                    },
                    [_vm._v(" " + _vm._s(_vm.speakers[0].position) + " ")]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm.speakersLength === 1 && _vm.mobCanWatch
        ? _c(
            "div",
            { staticClass: "schedule-content-days-day-watchers-conteiner-one" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    "content-class": "page_schedule_tooltip bottom",
                    bottom: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g(
                                {
                                  staticClass:
                                    "schedule-content-days-day-watchers-ava"
                                },
                                on
                              ),
                              [
                                _c("Avatar", {
                                  attrs: {
                                    small: "",
                                    src: _vm.getLinkByFileName(
                                      "speakers",
                                      "main",
                                      _vm.speakers[0].photo_in_schedule
                                    ),
                                    alt: _vm.speakers[0].name,
                                    size: "44"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1768276933
                  )
                },
                [
                  _c("div", { staticClass: "tooltip-arrow" }),
                  _c("div", { staticClass: "page-schedule-tooltip-content" }, [
                    _c("div", { staticClass: "schedule-tooltip-name" }, [
                      _vm._v(" " + _vm._s(_vm.speakers[0].name) + " ")
                    ]),
                    _c("div", { staticClass: "schedule-tooltip-position" }, [
                      _vm._v(" " + _vm._s(_vm.speakers[0].position) + " ")
                    ])
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.speakersLength > 1
        ? _c(
            "div",
            { staticClass: "schedule-content-days-day-watchers-conteiner" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "schedule-content-days-day-watchers-conteiner-multi"
                },
                _vm._l(_vm.speakers, function(s, i) {
                  return _c(
                    "div",
                    { key: i + "_" + s.email },
                    [
                      i < _vm.speakersShow
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: {
                                "content-class": "page_schedule_tooltip bottom",
                                bottom: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "schedule-content-days-day-watchers-ava multi",
                                              class: {
                                                "multi-hover":
                                                  _vm.hoverId !== null &&
                                                  _vm.hoverId !== i
                                              },
                                              on: {
                                                mouseover: function($event) {
                                                  return _vm.setHover(i)
                                                },
                                                mouseout: function($event) {
                                                  return _vm.setHover(null)
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("Avatar", {
                                              attrs: {
                                                small: "",
                                                src: _vm.getLinkByFileName(
                                                  "speakers",
                                                  "main",
                                                  s.photo_in_schedule
                                                ),
                                                alt: s.name,
                                                size: "44"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("div", { staticClass: "tooltip-arrow" }),
                              _c(
                                "div",
                                {
                                  staticClass: "page-schedule-tooltip-content"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "schedule-tooltip-name" },
                                    [_vm._v(" " + _vm._s(s.name) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "schedule-tooltip-position"
                                    },
                                    [_vm._v(" " + _vm._s(s.position) + " ")]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      i > _vm.speakersShow - 1 && i <= _vm.speakersShow
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: {
                                "content-class": "page_schedule_tooltip bottom",
                                bottom: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "schedule-content-days-day-watchers-ava multi plus"
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " +" +
                                                _vm._s(
                                                  _vm.speakersLength -
                                                    _vm.speakersShow
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("div", { staticClass: "tooltip-arrow" }),
                              _c(
                                "div",
                                {
                                  staticClass: "page-schedule-tooltip-content"
                                },
                                _vm._l(_vm.speakers, function(sp, j) {
                                  return _c(
                                    "div",
                                    {
                                      key: sp.email + "_" + j,
                                      staticClass: "schedule-tooltip-speakers"
                                    },
                                    [
                                      j >= _vm.speakersShow
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "schedule-tooltip-speaker"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "schedule-tooltip-ava"
                                                },
                                                [
                                                  _c("Avatar", {
                                                    attrs: {
                                                      small: "",
                                                      src: _vm.getLinkByFileName(
                                                        "speakers",
                                                        "main",
                                                        sp.photo_in_schedule
                                                      ),
                                                      alt: sp.name,
                                                      size: "44"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "schedule-tooltip-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(sp.name) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "schedule-tooltip-position"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(sp.position) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }