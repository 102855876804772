var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-content-days" },
    _vm._l(_vm.items, function(item) {
      return _c("Item", {
        key: item.id,
        staticClass: "schedule-content-days__item",
        attrs: { item: item, "show-description": _vm.showDescription }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }